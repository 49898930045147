import request from '@/utils/request'

//分红设置列表查询
export function dividentFit(data) {
  return request({
    url: '/api/sale/trade/bonus/list',
    method: 'post',
    data:data
  })
}
//分红设置修改
export function dividentUpdate(data) {
  return request({
    url: '/api/sale/trade/bonus/update',
    method: 'post',
    data:data
  })
}



