<template>
  <div class="app-wrap">
    <h3>分红设置</h3>
    <div class="fit-wrap">
      <table
        cellspacing="0"
        cellpadding="0"
        border="0"
      >
        <colgroup>
          <col width="184">
          <col width="152">
          <col width="130">
          <col width="130">
          <col width="130">
          <col width="119">
        </colgroup>
        <thead>
          <tr>
            <th align="left">
              基金名称
            </th>
            <th align="left">
              资产(元)
            </th>
            <th align="left">
              昨日收益
            </th>
            <th align="left">
              持有收益
            </th>
            <th align="left">
              当前分红方式
            </th>
            <th align="center">
              操作
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in list"
            :key="item.fund_code"
          >
            <td align="left">
              {{ item.fund_name }}
            </td>
            <td align="left">
              {{ item.fund_share }} 元
            </td>
            <td align="left">
              {{ item.today_income }} 元
            </td>
            <td align="left">
              {{ item.incomeByhold }} 元
            </td>
            <td
              align="center"
              :class="setting(item).class"
            >
              {{ setting(item).text }}
            </td>
            <td align="center">
              <span
                :class="setting(item).text === '未设置' ? 'set-btn' : 'de-btn'"
                @click="handleClick(item)"
              >
                {{ setting(item).text === '未设置' ? '设置' : '更改' }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="nodata" v-if="list.length === 0">
        <img src="@/assets/images/nodata123.png">
        <span>暂无更多数据</span>
      </div>
    </div>
    <zh-dialog
      ref="dialog"
      width="564"
      height="318"
      title="分红设置"
    >
      <div class="set-w">
        <p class="set-t">
          请为当前基金设置分红方式
        </p>
        <p class="set-name">
          {{ AcItem.fund_name }}
        </p>
        <div class="set-b-w">
          <div>
            <span
              :class="autoBuy === '0' ? 'active' : ''"
              @click="handleSelect('0')"
            >
              红利再投资
              <img
                v-show="autoBuy === '0'"
                src="@/assets/images/myFavors/check1.png"
              >
              <img
                v-show="autoBuy != '0'"
                src="@/assets/images/myFavors/check2.png"
              >
            </span>
            <!-- <em>*红利再投资的解释</em> -->
          </div>
          <div>
            <span
              :class="autoBuy === '1' ? 'active' : ''"
              @click="handleSelect('1')"
            >
              现金分红
              <img
                v-show="autoBuy === '1'"
                src="@/assets/images/myFavors/check1.png"
              >
              <img
                v-show="autoBuy != '1'"
                src="@/assets/images/myFavors/check2.png"
              >
            </span>
            <!-- <em>*现金分红的解释</em> -->
          </div>
        </div>
        <div class="btn-r">
          <span
            class="s-btn"
            @click="dividentUpdate"
          >确认</span>
        </div>
      </div>
    </zh-dialog>
  
  <!-- <h3>分红设置</h3>

    <div class="item">
      <div>基金名称</div>
      <div>资产(元)</div>
      <div>昨日收益</div>
      <div>持有收益</div>
      <div>当前分红方式</div>
      <div>操作</div>
    </div>

    <div
      v-for="item in list"
      :key="item"
      class="item"
    >
      <div>{{ item.fund_name }}</div>
      <div>{{ item.fund_share }} 元</div>
      <div>{{ item.today_income }} 元</div>
      <div>{{ item.incomeByhold }} 元</div>
      <div>{{ item.auto_buy }}</div>
      <button>更改/设置</button>
    </div>
  </div> -->
  </div>
</template>

<script>
import { dividentFit, dividentUpdate } from "@/api/dividentFit.js";

export default {
  name: "DividentFit",
  data() {
    return {
      list: [],
      clientId: "",
      // fundCode: '',
      autoBuy: "",
      AcItem: {}
    };
  },
  mounted() {
    this.clientId = (
      JSON.parse(localStorage.getItem("customerInfo")) || {}
    ).userId;
    this.dividentFit();
  },
  methods: {
    handleSelect(val) {
      this.autoBuy = val;
    },
    setting(item) {
      const data = {
        text: "未设置",
        class: ""
      };
      if (item.bonus_status === "1") {
        data.text = "待确认";
        data.class = "active";
      } else if (item.bonus_status === "2") {
        if (item.auto_buy === "0") {
          data.text = "红利再投资";
          data.class = "active";
        } else if (item.auto_buy === "1") {
          data.text = "现金分红";
          data.class = "active";
        }
      }
      return data;
    },
    handleClick(item) {
      this.AcItem = item;
      // this.fundCode = item.fund_code
      this.$refs["dialog"].open();
    },
    dividentFit() {
      dividentFit({
        client_id: this.clientId
      }).then(r => {
        this.list = r.data.map(item=>{
          return{
            ...item,
            fund_share:this.formatDecimal(item.fund_share,2)
          }
        });
      });
    },
    formatDecimal(num, decimal) {
      num = num.toString();
      let index = num.indexOf(".");
      if (index !== -1) {
        num = num.substring(0, decimal + index + 1);
      } else {
        num = num.substring(0);
      }
      return parseFloat(num).toFixed(decimal);
    },
    dividentUpdate() {
      if (this.AcItem.bonus_status === "1") {
        this.$message({
          message: "确认后才可修改",
          type: "warning"
        });
        return false;
      }
      dividentUpdate({
        client_id: this.clientId, // '26', //this.clientId,
        fund_code: this.AcItem.fund_code,
        auto_buy: this.autoBuy //0=红利再投资,1=现金红利
      }).then(() => {
        this.$message({
          message: "设置成功，等待确认！",
          type: "success"
        });
        this.$refs["dialog"].close();
        this.dividentFit();
      });
    }
  }
};
</script>

<style scoped lang="less">
@import url("@/assets/css/zc-common.less");
.app-wrap > h3 {
  width: 100%;
  font-weight: bold;
  font-size: 20px;
  color: #1f1f1f;
  border-bottom: 1px solid #edf1f7;
  padding-bottom: 30px;
  .border-box();
}
.fit-wrap {
  width: 100%;
  margin-top: 40px;
  & > table {
    width: 100%;
    border-top: 1px solid #f3f4f6;
    border-left: 1px solid #f3f4f6;
    thead {
      width: 100%;
      th {
        height: 60px;
        padding: 0 20px;
        font-size: 12px;
        color: #1f1f1f;
        border-right: 1px solid #f3f4f6;
        border-bottom: 1px solid #f3f4f6;
        font-weight: normal;
      }
    }
    tbody {
      width: 100%;
      td {
        height: 49px;
        padding: 0 20px;
        border-right: 1px solid #f3f4f6;
        border-bottom: 1px solid #f3f4f6;
        color: #25293d;
        font-size: 12px;
        &.active {
          color: #ce9b63;
        }
      }
    }
  }
}
.set-btn {
  .inline-flex(center, center, row);
  width: 96px;
  height: 26px;
  background: #deb87b;
  border-radius: 2px;
  color: #ffffff;
  font-size: 12px;
  cursor: pointer;
}
.de-btn {
  .inline-flex(center, center, row);
  width: 96px;
  height: 26px;
  background: #f9f9f9;
  border: 1px solid #e0e2e8;
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 12px;
  cursor: pointer;
}
.set-w {
  width: 100%;
  height: 100%;
  padding: 0 30px;
  .border-box();
  .set-t {
    width: 100%;
    font-size: 14px;
    color: #bdc0cb;
  }
  .set-name {
    width: 100%;
    margin-top: 30px;
    font-size: 20px;
    color: #1f1f1f;
  }
  .set-b-w {
    margin-top: 30px;
    width: 100%;
    .flex(space-between, center, row);
    & > div {
      width: 237px;
      .flex(center, flex-start, column);
      & > span {
        .inline-flex(space-between, center, row);
        padding: 10px;
        width: 100%;
        height: 40px;
        background: #ffffff;
        border: 1px solid #e0e2e8;
        box-sizing: border-box;
        border-radius: 2px;
        & > img {
          width: 18px;
          height: 18px;
        }
        &.active {
          border: 1px solid #ce9b63;
          color: #ce9b63;
        }
      }
      em {
        font-style: normal;
        margin-top: 14px;
        color: #bdc0cb;
        font-size: 12px;
      }
    }
  }
  .btn-r {
    width: 100%;
    text-align: right;
    margin-top: 19px;
    .s-btn {
      .btn(128px,48px);
      .inline-flex(center,center,row);
    }
  }
}
.nodata {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 35.5px;
  font-size: 14px;
  color: #a5a5a5;
  & > img {
    width: 13.97px;
    height: 13.97px;
    margin-right: 10px;
  }
}
</style>
